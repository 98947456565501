import React from "react"
import { graphql } from "gatsby"
// import DOMPurify from "dompurify"
import Layout from "../components/layout"
import SEO from "../components/seo"

const WPpage = ({ data }) => (
  <Layout>
    <SEO 
    	title={data.wordpressPage.title}
    	description={data.wordpressPage.excerpt}
    />
    {data.wordpressPage.title &&
    	<h1>{data.wordpressPage.title}</h1>
    }
    {data.wordpressPage.content &&
	    <div 
	    	style={{ marginTop: 20 }}
	    	dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }}
	    />
	}
  </Layout>
)

export default WPpage

export const query = graphql`
	query($id: Int!) {
		wordpressPage(wordpress_id: { eq: $id }) {
			title
			content
			excerpt
		}
	}
`
